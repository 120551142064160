import React from 'react';
import './UnderConstruction.scss';
import underConstructionImage from './under-construction.png';

const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <img src={underConstructionImage} alt="Under Construction" className="under-construction__image" />
      <p className="under-construction__text">
        This page is currently under construction. Please check back later!
      </p>
    </div>
  );
};

export default UnderConstruction;
